import AddIcon from '@mui/icons-material/Add';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/material/styles';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button, { ButtonVariation } from '../../components/button';
import FullScreenCircularLoader from '../../components/fullScreenCircularLoader';
import { SnackBarHOCProps, withSnackbar } from '../../components/snackbar';
import { useUrlParams } from '../../hooks/useParam';
import CMPTexts from '../../locale/en';
import {
    assetOperationLoaderReset,
    getAssetLists,
    resetLoader
} from '../../redux/assets/action';
import {
    selectAssetOperationLoadingState,
    selectAssetsLoader,
    selectMessage
} from '../../redux/assets/reducer';
import { LoadingStatus } from '../../redux/common/state';
import { pxToRem } from '../../utils/stylesUtils';
import AssetsTable from './assetsTable';
import UploadAssetModal, { MULTI_UPLOAD_FILE_LIMIT } from './uploadAssetModal';

const AssetsWrapper = styled('div')(() => ({
    margin: `0 ${pxToRem(43)}`,
    textAlign: 'left',
    width: '100%'
}));
const AssetsHeading = styled('h1')(() => ({
    fontSize: pxToRem(28),
    fontWeight: 500,
    lineHeight: pxToRem(33)
}));
const InfoHeading = styled('div')(() => ({
    fontWeight: 500,
    position: 'relative'
}));
const InfoText = styled('span')(() => ({
    position: 'absolute',
    bottom: pxToRem(6),
    paddingLeft: pxToRem(10)
}));

const Assets = (props: SnackBarHOCProps) => {
    const { snackbarShowMessage } = props;
    const { property } = useUrlParams();
    const dispatch = useDispatch();

    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const assetOperationLoader = useSelector(selectAssetOperationLoadingState);
    const loader = useSelector(selectAssetsLoader);
    const message = useSelector(selectMessage);

    useEffect(() => {
        if (loader === LoadingStatus.DONE) {
            dispatch(resetLoader());
        } else if (loader === LoadingStatus.FAILED) {
            snackbarShowMessage(message, 'error');
            dispatch(resetLoader());
        }
    }, [loader]);

    useEffect(() => {
        if (assetOperationLoader === LoadingStatus.DONE) {
            dispatch(getAssetLists(property));
            dispatch(assetOperationLoaderReset());
            snackbarShowMessage(message);
        } else if (assetOperationLoader === LoadingStatus.FAILED) {
            snackbarShowMessage(message, 'error');
            dispatch(assetOperationLoaderReset());
        }
    }, [assetOperationLoader]);

    return (
        <Fragment>
            {((!isUploadModalOpen &&
                assetOperationLoader === LoadingStatus.INITIATED) ||
                loader === LoadingStatus.INITIATED) && (
                <FullScreenCircularLoader />
            )}
            <AssetsWrapper>
                <AssetsHeading>{CMPTexts.assets.heading}</AssetsHeading>
                <InfoHeading>
                    <InfoOutlinedIcon />
                    <InfoText>{CMPTexts.assets.info}</InfoText>
                </InfoHeading>
                <Button
                    buttonVariant={ButtonVariation.CONTAINED}
                    endIcon={<AddIcon />}
                    onClick={() => setIsUploadModalOpen(true)}
                    sx={{ margin: `${pxToRem(20)} 0` }}
                >
                    {CMPTexts.assets.uploadAssetButtonText}
                </Button>
                {isUploadModalOpen && (
                    <UploadAssetModal
                        filesLimit={MULTI_UPLOAD_FILE_LIMIT}
                        setIsModalOpen={setIsUploadModalOpen}
                    />
                )}
                <AssetsTable property={property} />
            </AssetsWrapper>
        </Fragment>
    );
};

export default withSnackbar(Assets);
