import { isEmpty } from 'lodash';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ApiResponse } from '../../api/apiInterfaces';
import { ActionPayload } from '../types';
import {
    GET_EVOLVE2_FIELDS,
    putEvolve2ConfigResults,
    updateEvolve2ConfigResults,
    UPDATE_EVOLVE2_FIELDS
} from './action';
import {
    fetchEvolve2FieldConfig,
    updateEvolve2Fields,
    updateEvolve2Status
} from './helper';
import { Evove2ConfigRequestBody } from './model';
import { PropertyField } from '../propertyValue/model';

/*  Evolve2 SECTION FETCH     */
function* evolve2FetchWorker(data: ActionPayload<string>) {
    try {
        const evolve2SectionResult: ApiResponse<{
            evolve2_provisioning: PropertyField[];
        }> = yield call(fetchEvolve2FieldConfig, data);

        console.log('evolve2FetchWorker', evolve2SectionResult.response_body);
        yield put(
            putEvolve2ConfigResults(
                false,
                '',
                evolve2SectionResult.response_body.evolve2_provisioning
            )
        );
    } catch (error: any) {
        yield put(putEvolve2ConfigResults(true, error.message));
    }
}

function* evolve2ConfigUpdateWorker(
    data: ActionPayload<Evove2ConfigRequestBody>
) {
    try {
        const evolve2StatusUpdateResponse: ApiResponse<{}> = yield call(
            updateEvolve2Status,
            data
        );
        if (data.payload.evolve2_enabled && !isEmpty(data.payload.fields))
            yield call(updateEvolve2Fields, data);
        yield put(
            updateEvolve2ConfigResults(
                false,
                evolve2StatusUpdateResponse.message
            )
        );
    } catch (error: any) {
        yield put(updateEvolve2ConfigResults(true, error.message));
    }
}

export function* watchEvolve2Sagas() {
    yield all([
        takeLatest(GET_EVOLVE2_FIELDS, evolve2FetchWorker),
        takeLatest(UPDATE_EVOLVE2_FIELDS, evolve2ConfigUpdateWorker)
    ]);
}
