import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import {
    Box,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    styled,
    Typography
} from '@mui/material';
import { head, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withSnackbar } from '../../components/snackbar';
import videoThumbnail from '../../images/Thumbnail_Video.png';
import cmpTexts from '../../locale/en';
import { selectAssetsLoader } from '../../redux/assets/reducer';
import { Asset } from '../../redux/assets/model';
import { LoadingStatus } from '../../redux/common/state';
import { MediaType } from '../../redux/element/model';
import { dataSizeConverter } from '../../utils/sizeConverter';
import { pxToRem } from '../../utils/stylesUtils';
import UploadAssetModal from '../assets/uploadAssetModal';

const ImageComponent = styled('img')(() => ({
    backgroundColor: '#eeeeee',
    padding: pxToRem(8),
    width: pxToRem(100),
    height: pxToRem(55)
}));

const ItemsContainer = styled('div')(() => ({
    display: 'grid',
    justifyContent: 'space-around',
    width: '100%',
    gridTemplateColumns: 'repeat(4, 1fr)'
}));

const AssetName = styled(Typography)(() => ({
    color: '#171725',
    fontSize: pxToRem(18),
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: pxToRem(44),
    maxWidth: 160,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}));

const SelectedAssetContainer = styled(Typography)(() => ({
    border: `${pxToRem(1)} dashed #292938`,
    padding: `${pxToRem(11)} ${pxToRem(15)} ${pxToRem(10)} ${pxToRem(17)}`,
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
}));

const SelectedAssetText = styled(Typography)(() => ({
    fontSize: pxToRem(18),
    letterSpacing: 0,
    lineHeight: pxToRem(22)
}));

const SelectedAssetTextContainer = styled('div')(() => ({
    color: '#181818',
    paddingLeft: pxToRem(20)
}));

const AssetContentWrapper = styled('div')(() => ({
    display: 'flex'
}));

const IconsWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center'
}));

const UploadAsset = styled(MenuItem)(() => ({
    color: '#F01446',
    fontSize: pxToRem(16),
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: pxToRem(17),
    textAlign: 'center'
}));

interface AssetDropDownProps {
    name: string;
    assets: Asset[];
    selectedAssetId: string;
    mediaType: MediaType;
    onAssetSelected: (assetid: string) => void;
    isRequired: boolean;
}

const AssetSelectionDropDown = (props: AssetDropDownProps) => {
    const [showUploadModal, toggleUploadModal] = useState(false);
    const {
        name,
        selectedAssetId,
        onAssetSelected,
        assets,
        mediaType,
        isRequired
    } = props;
    const [dropDownOpen, setDropDownOpen] = useState(false);
    const [selectedAssetDetails, setSelectedAssetDetails] = useState<
        Asset | undefined
    >(undefined);
    const assetLoader = useSelector(selectAssetsLoader);
    const hasError = isRequired && isEmpty(selectedAssetId);

    useEffect(() => {
        if (!isEmpty(selectedAssetId)) {
            const selectedAsset = head(
                assets.filter((item) => item.id === selectedAssetId)
            );
            if (!isEmpty(selectedAsset)) setSelectedAssetDetails(selectedAsset);
        } else {
            setSelectedAssetDetails(undefined);
        }
    }, [selectedAssetId, assets]);

    return (
        <>
            {selectedAssetDetails ? (
                <SelectedAssetContainer
                    onClick={() => {
                        onAssetSelected('');
                        setDropDownOpen(true);
                    }}
                >
                    <AssetContentWrapper>
                        <ImageComponent
                            src={
                                selectedAssetDetails.type.toLocaleLowerCase() ===
                                'video'
                                    ? videoThumbnail
                                    : selectedAssetDetails.url
                            }
                            alt={name}
                        />
                        <SelectedAssetTextContainer>
                            <SelectedAssetText>
                                {`${
                                    selectedAssetDetails.name
                                }.${selectedAssetDetails.file_extension.toLowerCase()}`}
                            </SelectedAssetText>

                            <SelectedAssetText>
                                {dataSizeConverter(selectedAssetDetails.size)}
                            </SelectedAssetText>
                        </SelectedAssetTextContainer>
                    </AssetContentWrapper>
                    <IconsWrapper>
                        <IconButton
                            onClick={(e) => {
                                // Download has to be fixed.
                                e.stopPropagation();
                                window.open(selectedAssetDetails.url);
                            }}
                        >
                            <DownloadIcon sx={{ color: 'black' }} />
                        </IconButton>

                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                onAssetSelected('');
                            }}
                        >
                            <DeleteIcon sx={{ color: 'black' }} />
                        </IconButton>
                    </IconsWrapper>
                </SelectedAssetContainer>
            ) : (
                <FormControl fullWidth>
                    <InputLabel id="select-label">{`Select ${name}`}</InputLabel>
                    <Select
                        label={`Select ${name}`}
                        value={selectedAssetId}
                        MenuProps={{
                            PaperProps: { sx: { maxHeight: '20rem' } }
                        }}
                        open={dropDownOpen}
                        onOpen={() => setDropDownOpen(true)}
                        onClose={() => setDropDownOpen(false)}
                        onChange={(e) => onAssetSelected(e.target.value)}
                        required={isRequired}
                        error={hasError}
                    >
                        {assetLoader === LoadingStatus.INITIATED && (
                            <MenuItem>
                                <Box sx={{ width: '100%' }}>
                                    <LinearProgress />
                                </Box>
                            </MenuItem>
                        )}
                        <UploadAsset
                            onClick={() => {
                                toggleUploadModal(true);
                            }}
                            value={selectedAssetId}
                        >
                            <AddIcon sx={{ marginRight: pxToRem(11) }} />
                            {cmpTexts.configureElement.uploadAsset}
                        </UploadAsset>
                        {assets.map((asset: Asset) => {
                            const {
                                id,
                                name,
                                url,
                                file_extension: fileType,
                                size
                            } = asset;
                            const isVideoAsset =
                                asset.type.toLocaleLowerCase() === 'video';
                            return (
                                <MenuItem key={id} value={id}>
                                    <ItemsContainer>
                                        <ImageComponent
                                            src={
                                                isVideoAsset
                                                    ? videoThumbnail
                                                    : url
                                            }
                                            alt={name}
                                        />
                                        <AssetName>{name}</AssetName>
                                        <Typography
                                            sx={{ lineHeight: pxToRem(44) }}
                                        >
                                            {fileType}
                                        </Typography>
                                        <Typography
                                            sx={{ lineHeight: pxToRem(44) }}
                                        >
                                            {dataSizeConverter(size)}
                                        </Typography>
                                    </ItemsContainer>
                                </MenuItem>
                            );
                        })}
                    </Select>
                    {hasError && (
                        <FormHelperText
                            error={hasError}
                            required={isRequired}
                            id="select-label-helper-text"
                        >
                            *Required
                        </FormHelperText>
                    )}
                </FormControl>
            )}
            {showUploadModal && (
                <UploadAssetModal
                    mediaType={mediaType}
                    setIsModalOpen={toggleUploadModal}
                    onAssetUploaded={(asset: Asset) => {
                        if (
                            asset.type.toLocaleLowerCase() ===
                            mediaType.toLocaleLowerCase()
                        )
                            onAssetSelected(asset.id);
                    }}
                />
            )}
        </>
    );
};

export default withSnackbar(AssetSelectionDropDown);
