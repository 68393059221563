import {
    styled,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography
} from '@mui/material';
import React from 'react';
import { pxToRem } from '../utils/stylesUtils';

interface CustomTooltipProps {
    message: string;
    children: React.ReactElement;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11
    }
}));

const CustomTooltip = (props: CustomTooltipProps): React.ReactElement => {
    return (
        <LightTooltip
            title={
                <Typography sx={{ fontSize: pxToRem(14) }}>
                    {props.message}
                </Typography>
            }
            sx={{ order: 1 }}
        >
            {props.children}
        </LightTooltip>
    );
};

export default CustomTooltip;
