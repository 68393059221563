import { last } from 'lodash';
import {
    ContentField,
    ContentFieldsKey,
    HeaderVariants,
    PageAlias,
    RailAlias,
    RewardsSubFields,
    WidgetAlias,
    WidgetSkeleton
} from '../../../redux/element/model';
import { ElementsData } from '../../../redux/elementsList/model';
import { WidgetData } from './models/baseWidgetData';
import {
    HomePageData,
    HotelInfoAndWatchPageData,
    MyStayPageData,
    PageData
} from './models/pageDataModels';
import { RailData, RailDataWithHeader } from './models/railDataModels';
import {
    LinkWidgetData,
    LiveChannelWidgetData,
    MoreInfoVideoWidgetData,
    MoreInfoWidgetData,
    NotificationWidgetData,
    StaticAdWidgetData
} from './models/widgetDataModels';

class WidgetDataAdapter {
    getBasicWidgetData(e: ElementsData): WidgetData {
        return {
            name: e.name,
            alias: e.alias,
            id: e.id,
            typeId: e.widget_type_id,
            category: e.type,
            widgetTypeName: last(e.widget_type_name.split('-'))!,
            accessDetails: e.access_details,
            isModified: false,
            modifiedDate: e.modified_date,
            modifiedBy: e.modified_by,
            hasError: false,
            attached_to: e.attached_to,
            canInstantPublish: e.can_instant_publish
        };
    }

    transformToWidgetDataList(elementsData: ElementsData[]): WidgetData[] {
        const widgetsData: WidgetData[] = [];
        elementsData.forEach((e) => {
            const wd = this.transformToWidgetData(e);
            if (wd !== null) widgetsData.push(wd);
        });
        return widgetsData;
    }

    transformToWidgetData(e: ElementsData): WidgetData | null {
        switch (e.alias) {
            case WidgetAlias.WidgetMoreInfo: {
                const moreInfoWidgetData: MoreInfoWidgetData = {
                    ...this.getBasicWidgetData(e),
                    headline: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.Headline
                    )!,
                    subHeading: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.SubHeading
                    )!,
                    bodyText: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.BodyText
                    )!,
                    icon: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.Icon
                    )!,
                    heroImage: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.HeroImage
                    )!,
                    link: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.Link
                    )!
                };
                return moreInfoWidgetData;
            }

            case WidgetAlias.WidgetMoreInfoVideo: {
                const moreInfoVideoWidgetData: MoreInfoVideoWidgetData = {
                    ...this.getBasicWidgetData(e),
                    headline: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.Headline
                    )!,
                    subHeading: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.SubHeading
                    )!,
                    bodyText: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.BodyText
                    )!,
                    icon: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.Icon
                    )!,
                    poster: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.PosterImage
                    )!,
                    video: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.VideoBackground
                    )!,
                    link: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.Link
                    )!
                };
                return moreInfoVideoWidgetData;
            }

            case WidgetAlias.WidgetStaticAd: {
                const staticAdWidgetData: StaticAdWidgetData = {
                    ...this.getBasicWidgetData(e),
                    adHeroImage: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.AdHeroImage
                    )!,
                    headline: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.Headline
                    )!,
                    subHeading: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.SubHeading
                    )!,
                    bodyText: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.BodyText
                    )!,
                    icon: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.Icon
                    )!,
                    link: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.Link
                    )!
                };
                return staticAdWidgetData;
            }

            case WidgetAlias.WidgetNotification: {
                const notificationWidgetData: NotificationWidgetData = {
                    ...this.getBasicWidgetData(e),
                    headline: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.Headline
                    )!,
                    bodyText: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.BodyText
                    )!,
                    icon: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.Icon
                    )!,
                    link: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.Link
                    )!
                };
                return notificationWidgetData;
            }

            case WidgetAlias.WidgetDeepLink: {
                const linkWidgetData: LinkWidgetData = {
                    ...this.getBasicWidgetData(e),
                    icon: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.Icon
                    )!,
                    image: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.Image
                    )!,
                    linkText: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.LinkText
                    )!,
                    linkDestination: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.JSON
                    )!
                };
                return linkWidgetData;
            }

            case WidgetAlias.WidgetLiveChannel: {
                const liveChannelWidgetData: LiveChannelWidgetData = {
                    ...this.getBasicWidgetData(e),
                    channelId: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.ChannelId
                    )!
                };
                return liveChannelWidgetData;
            }

            case WidgetAlias.WidgetSports:
            case WidgetAlias.WidgetWeather: {
                return this.getBasicWidgetData(e);
            }

            // rails
            case RailAlias.HeroCarousel:
            case RailAlias.Carousel_728x90: {
                const widgets = e.content_fields?.find(
                    (cf) => cf.key === ContentFieldsKey.WidgetContents
                )!;
                if (widgets.values === null || widgets.values === undefined)
                    widgets.values = [];

                const railData: RailData = {
                    ...this.getBasicWidgetData(e),
                    widgets: widgets
                };
                return railData;
            }

            case RailAlias.WidgetRail_300x166:
            case RailAlias.WidgetRail_350x284: {
                const widgets = e.content_fields?.find(
                    (cf) => cf.key === ContentFieldsKey.WidgetContents
                )!;
                const header = e.content_fields?.find(
                    (cf) => cf.key === ContentFieldsKey.Header
                )!;

                if (!widgets.values) widgets.values = [];
                if (!header.values) header.values = '';

                const railData: RailDataWithHeader = {
                    ...this.getBasicWidgetData(e),
                    widgets: widgets,
                    //@ts-ignore
                    header: header
                };
                return railData;
            }

            // pages
            case PageAlias.HotelInfoPage:
            case PageAlias.WatchPage: {
                const rails = e.content_fields.find(
                    (cf) => cf.key === ContentFieldsKey.ContentContainers
                )!;
                if (!rails.values) rails.values = [];
                const hotelInfoAndWatchPageData: HotelInfoAndWatchPageData = {
                    ...this.getBasicWidgetData(e),
                    background: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.BackgroundImage
                    )!,
                    rails: rails
                };
                return hotelInfoAndWatchPageData;
            }

            case PageAlias.AppsPage:
            case PageAlias.GenericPage: {
                const rails = e.content_fields.find(
                    (cf) => cf.key === ContentFieldsKey.ContentContainers
                )!;
                if (!rails.values) rails.values = [];
                const appsPageData: PageData = {
                    ...this.getBasicWidgetData(e),
                    rails: rails
                };
                return appsPageData;
            }

            case PageAlias.HomePage: {
                const rewards = e.content_fields.find(
                    (cf) => cf.key === ContentFieldsKey.Rewards
                )!;
                const welcomeMessage1 = e.content_fields.find(
                    (cf) => cf.key === ContentFieldsKey.WelcomeMessage1
                )!;
                const rails = e.content_fields.find(
                    (cf) => cf.key === ContentFieldsKey.ContentContainers
                )!;

                if (!rewards.values) {
                    const emptyRewardsSubfield: RewardsSubFields = {
                        rewardMessage: '',
                        buttonText: '',
                        rewardsUrl: ''
                    };
                    rewards.values = {
                        enabled: false,
                        currentRewardMember: emptyRewardsSubfield,
                        notARewardMember: emptyRewardsSubfield,
                        rewardsInformationNotAvailable: emptyRewardsSubfield
                    };
                }

                if (!rails.values) rails.values = [];

                if (!welcomeMessage1.values) {
                    const emptyHeader: HeaderVariants = {
                        greeting: '',
                        message: '',
                        subHeadline: ''
                    };
                    welcomeMessage1.values = emptyHeader;
                }

                const homePageData: HomePageData = {
                    ...this.getBasicWidgetData(e),
                    rewards: rewards,
                    welcomeMessage1: welcomeMessage1,
                    background: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.BackgroundImage
                    )!,
                    rails: rails
                };
                console.log(homePageData);
                return homePageData;
            }

            case PageAlias.MyStayPage: {
                const rewards = e.content_fields.find(
                    (cf) => cf.key === ContentFieldsKey.Rewards
                )!;
                const welcomeMessage2 = e.content_fields.find(
                    (cf) => cf.key === ContentFieldsKey.WelcomeMessage2
                )!;
                const rails = e.content_fields.find(
                    (cf) => cf.key === ContentFieldsKey.ContentContainers
                )!;

                if (!rewards.values) {
                    const emptyRewardsSubfield: RewardsSubFields = {
                        rewardMessage: '',
                        buttonText: '',
                        rewardsUrl: ''
                    };
                    rewards.values = {
                        enabled: false,
                        currentRewardMember: emptyRewardsSubfield,
                        notARewardMember: emptyRewardsSubfield,
                        rewardsInformationNotAvailable: emptyRewardsSubfield
                    };
                }

                if (!rails.values) rails.values = [];

                if (!welcomeMessage2.values) {
                    const emptyHeader: HeaderVariants = {
                        greeting: '',
                        message: '',
                        subHeadline: ''
                    };
                    welcomeMessage2.values = emptyHeader;
                }
                const myStayPageData: MyStayPageData = {
                    ...this.getBasicWidgetData(e),
                    rewards: rewards,
                    welcomeMessage2: welcomeMessage2,
                    background: e.content_fields.find(
                        (cf) => cf.key === ContentFieldsKey.BackgroundImage
                    )!,
                    rails: rails
                };
                return myStayPageData;
            }

            default:
                return null;
        }
    }

    createEmptyWidgetFromSkeleton(
        widgetSkeleton: WidgetSkeleton
    ): WidgetData | null {
        const newElementData: ElementsData = {
            ...widgetSkeleton,
            widget_type_name: widgetSkeleton.name,
            widget_type_id: widgetSkeleton.id,
            name: '',
            modified_by: '',
            modified_date: new Date()
        };
        return this.transformToWidgetData(newElementData);
    }

    // Transforms to widget skeleton data that is being currently used by create/save element actions
    transformToWidgetSkeletonData(
        widgetData: WidgetData
    ): WidgetSkeleton | null {
        let contentFields: ContentField[];

        switch (widgetData.alias) {
            case WidgetAlias.WidgetMoreInfo: {
                const wd = widgetData as MoreInfoWidgetData;
                contentFields = [
                    wd.headline,
                    wd.subHeading,
                    wd.bodyText,
                    wd.icon,
                    wd.heroImage,
                    wd.link
                ];
                break;
            }

            case WidgetAlias.WidgetMoreInfoVideo: {
                const wd = widgetData as MoreInfoVideoWidgetData;
                contentFields = [
                    wd.headline,
                    wd.subHeading,
                    wd.bodyText,
                    wd.icon,
                    wd.poster,
                    wd.video,
                    wd.link
                ];
                break;
            }

            case WidgetAlias.WidgetStaticAd: {
                const wd = widgetData as StaticAdWidgetData;
                contentFields = [
                    wd.headline,
                    wd.subHeading,
                    wd.bodyText,
                    wd.icon,
                    wd.adHeroImage,
                    wd.link
                ];
                break;
            }

            case WidgetAlias.WidgetNotification: {
                const wd = widgetData as NotificationWidgetData;
                contentFields = [wd.headline, wd.bodyText, wd.icon, wd.link];
                break;
            }

            case WidgetAlias.WidgetDeepLink: {
                const wd = widgetData as LinkWidgetData;
                contentFields = [
                    wd.icon,
                    wd.image,
                    wd.linkDestination,
                    wd.linkText
                ];
                break;
            }

            case WidgetAlias.WidgetLiveChannel: {
                const wd = widgetData as LiveChannelWidgetData;
                const channelIdCF = wd.channelId;
                channelIdCF.values = Number(channelIdCF.values);

                contentFields = [channelIdCF];
                break;
            }

            case WidgetAlias.WidgetSports:
            case WidgetAlias.WidgetWeather: {
                contentFields = [];
                break;
            }

            // rails
            case RailAlias.HeroCarousel:
            case RailAlias.Carousel_728x90: {
                const rd = widgetData as RailData;
                contentFields = [rd.widgets];
                break;
            }

            case RailAlias.WidgetRail_300x166:
            case RailAlias.WidgetRail_350x284: {
                const rd = widgetData as RailDataWithHeader;
                contentFields = [rd.header, rd.widgets];
                break;
            }

            // pages
            case PageAlias.HotelInfoPage:
            case PageAlias.WatchPage: {
                const pd = widgetData as HotelInfoAndWatchPageData;
                contentFields = [pd.rails, pd.background];
                break;
            }

            case PageAlias.AppsPage:
            case PageAlias.GenericPage: {
                const pd = widgetData as PageData;
                contentFields = [pd.rails];
                break;
            }

            case PageAlias.HomePage: {
                const pd = widgetData as HomePageData;
                contentFields = [
                    pd.rails,
                    pd.rewards,
                    pd.welcomeMessage1,
                    pd.background
                ];
                break;
            }

            case PageAlias.MyStayPage: {
                const pd = widgetData as MyStayPageData;
                contentFields = [
                    pd.rails,
                    pd.rewards,
                    pd.welcomeMessage2,
                    pd.background
                ];
                break;
            }

            default:
                return null;
        }

        const widgetSkeleton: WidgetSkeleton = {
            name: widgetData.name,
            id: widgetData.id,
            type: widgetData.category,
            widget_type_id: widgetData.typeId,
            widget_type_name: widgetData.widgetTypeName,
            alias: widgetData.alias,
            content_fields: contentFields,
            short_description: '',
            description: '',
            associated_to: '',
            association_id: '',
            status: '',
            preview_url: '',
            icon_url: '',
            access_details: widgetData.accessDetails,
            is_live: false,
            can_instant_publish: false,
            attached_to: null
        };

        return widgetSkeleton;
    }
}

export default new WidgetDataAdapter();
