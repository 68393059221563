import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Grid, IconButton, InputAdornment, Link, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import LoginBroadcastChannel from '../../auth/loginBroadcastChannel';
import Button, { ButtonVariation } from '../../components/button';
import CMPBackground from '../../components/cmpBackground';
import FullScreenCircularLoader from '../../components/fullScreenCircularLoader';
import { SnackBarHOCProps, withSnackbar } from '../../components/snackbar';
import TextField from '../../components/textField';
import CMPTexts from '../../locale/en';
import { setLoginStatus } from '../../redux/login/action';
import { LoginStatus } from '../../redux/login/model';
import {
    selectAuthStatus,
    selectError,
    selectLoginstatus
} from '../../redux/login/reducer';
import { loginValidationSchema } from '../../utils/loginUtils';
import { pxToRem } from '../../utils/stylesUtils';

const CustomPaper = styled(Paper)(() => ({
    width: '30vw',
    marginTop: '30vh'
}));

const PaperHeadingBox = styled(Box)(() => ({
    textAlign: 'left',
    fontWeight: 1500,
    fontSize: 28,
    marginBottom: 25,
    marginTop: `${pxToRem(18)}`
}));

const CustomTextField = styled(TextField)(() => ({
    marginTop: `${pxToRem(28)} `
}));

const ForgotPasswordBox = styled(Box)(() => ({
    textAlign: 'right',
    marginTop: `${pxToRem(15)} `
}));

const CustomButton = styled(Button)(() => ({
    marginTop: 15,
    width: '35%',
    height: '12%',
    fontSize: 12,
    marginBottom: 40
}));

const LinkComponent = styled('div')(() => ({
    fontSize: 15,
    fontWeight: 400,
    '&:hover': { cursor: 'pointer' }
}));

const IconButtonComponent = styled(IconButton)(() => ({
    fontSize: 2
}));

function Login(props: SnackBarHOCProps) {
    const { snackbarShowMessage } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loginStatus = useSelector(selectLoginstatus);
    // const operationStatus = useSelector(getOperationLoaderStatus);
    const loginError = useSelector(selectError);
    const [showPassword, setShowPassword] = useState(false);
    const isAuthenticated = useSelector(selectAuthStatus);

    useEffect(() => {
        LoginBroadcastChannel.initLoginChannel();
    }, []);

    useEffect(() => {
        if (loginStatus === LoginStatus.FAILED) {
            dispatch(setLoginStatus(LoginStatus.NONE)); // To prevent error snackbar to popup again and again because state is persisted
            snackbarShowMessage(loginError, 'error');
            console.error('Login Failed');
        } else if (isAuthenticated) {
            navigate('/properties', { replace: true });
            dispatch(setLoginStatus(LoginStatus.NONE));
        }
    }, [loginStatus, loginError]);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: loginValidationSchema,
        onReset: () => {},
        onSubmit: () => {}
    });

    function handleClick() {
        console.log('Redirecting to Forgot Password');
        navigate('/forgot_password');
    }

    async function handleSubmit(e: React.SyntheticEvent) {
        e.preventDefault();
        try {
            console.log(`Logging In ${formik.values.email}...`);
            LoginBroadcastChannel.triggerLogin(formik.values);
        } catch (error) {
            console.log('Error logging in - ', error);
        }
    }

    if (loginStatus === LoginStatus.SUCCESS && isAuthenticated)
        return <Navigate to="/properties" replace />;

    return (
        <>
            {loginStatus === LoginStatus.LOGGING_IN && (
                <FullScreenCircularLoader />
            )}
            <CMPBackground>
                <form onSubmit={handleSubmit}>
                    <CustomPaper elevation={3}>
                        <Box sx={{ height: 10 }} />
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={1}></Grid>
                            <Grid item xs={10}>
                                <PaperHeadingBox>
                                    {CMPTexts.login.paperHeading}
                                </PaperHeadingBox>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={10}>
                                <TextField
                                    size="small"
                                    required
                                    id="email"
                                    label={CMPTexts.login.textFieldEmail}
                                    variant="outlined"
                                    fullWidth
                                    {...formik.getFieldProps('email')}
                                    error={Boolean(formik.errors.email)}
                                    helperText={
                                        formik.touched.email
                                            ? formik.errors.email
                                            : ''
                                    }
                                />
                                <CustomTextField
                                    size="small"
                                    required
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    label={CMPTexts.login.textFieldPassword}
                                    variant="outlined"
                                    fullWidth
                                    {...formik.getFieldProps('password')}
                                    error={Boolean(formik.errors.password)}
                                    helperText={
                                        formik.touched.password
                                            ? formik.errors.password
                                            : ''
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButtonComponent
                                                    aria-label="toggle password visibility"
                                                    onClick={() =>
                                                        setShowPassword(
                                                            !showPassword
                                                        )
                                                    }
                                                >
                                                    {!showPassword ? (
                                                        <Visibility fontSize="small" />
                                                    ) : (
                                                        <VisibilityOff fontSize="small" />
                                                    )}
                                                </IconButtonComponent>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={10}>
                                <ForgotPasswordBox>
                                    <LinkComponent>
                                        <Link
                                            color="inherit"
                                            onClick={handleClick}
                                            underline="none"
                                        >
                                            {CMPTexts.login.forgotPassword}{' '}
                                        </Link>
                                    </LinkComponent>
                                </ForgotPasswordBox>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center">
                            <CustomButton
                                type="submit"
                                buttonVariant={ButtonVariation.CONTAINED}
                                disabled={!(formik.isValid && formik.dirty)}
                            >
                                {CMPTexts.login.login}
                            </CustomButton>
                        </Grid>
                    </CustomPaper>
                </form>
            </CMPBackground>
        </>
    );
}
export default withSnackbar(Login);
