import { LoadingStatus } from '../common/state';
import { emptyPropertyFieldValues, PropertyFieldValues } from './model';
export interface PropertyValue {
    fieldConfigValues: PropertyFieldValues;
    propertyValueOperationLoader: LoadingStatus;
    propertyValueLoader: LoadingStatus;
    error: string;
}

export const initialPropertyValueState: PropertyValue = {
    fieldConfigValues: emptyPropertyFieldValues,
    propertyValueOperationLoader: LoadingStatus.NONE,
    propertyValueLoader: LoadingStatus.NONE,
    error: ''
};
