import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    Typography
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import cmpTexts from '../../locale/en';
import TextField from '../../components/textField';
import { ContentField, WifiDetail } from '../../redux/element/model';
import { pxToRem, StylesDictionary } from '../../utils/stylesUtils';
import { StyledInputAdornment } from './elementFormGenerator';
import {
    getCharacterLimit,
    WifiGeneratorSchema
} from '../../utils/configureElementUtil';

const styles: StylesDictionary = {
    checkboxLabel: {
        color: '#181818',
        fontSize: pxToRem(18),
        letterSpacing: 0,
        lineHeight: pxToRem(20)
    }
};

const emptyWifiDetail: WifiDetail = {
    wifiMessage: {
        enabled: false,
        message: ''
    },
    networkCredentials: {
        enabled: false,
        networkName: '',
        networkPassword: ''
    }
};

function WifiGenerator(props: {
    item: ContentField;
    setIsEdited: (isEdited: boolean) => void;
    onWifiChange: (wifiObj: WifiDetail) => void;
}) {
    const { item, setIsEdited, onWifiChange } = props;

    const initialRewardsMessages = item.values;
    const formik = useFormik({
        initialValues: initialRewardsMessages
            ? (initialRewardsMessages as WifiDetail)
            : emptyWifiDetail,
        validationSchema: WifiGeneratorSchema,
        onSubmit: () => {}
    });

    useEffect(() => {
        onWifiChange(formik.values);
        setIsEdited(
            formik.dirty &&
                formik.isValid &&
                (formik.values.wifiMessage.enabled ||
                    formik.values.networkCredentials.enabled)
        );
    }, [formik.dirty, formik.values, formik.isValid]);
    return (
        <>
            <FormGroup sx={{ float: 'right' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={formik.values.wifiMessage.enabled}
                            {...formik.getFieldProps('wifiMessage.enabled')}
                        />
                    }
                    label={
                        <Typography sx={styles.checkboxLabel}>
                            Enable
                        </Typography>
                    }
                />
            </FormGroup>
            <TextField
                sx={{ marginBottom: pxToRem(40) }}
                disabled={!formik.values.wifiMessage.enabled}
                id="outlined-basic"
                label="Wifi Details Message"
                variant="outlined"
                {...formik.getFieldProps('wifiMessage.message')}
                value={formik.values.wifiMessage.message}
                InputLabelProps={{ shrink: true }}
                placeholder={cmpTexts.wifiGenerator.messagePlaceholder}
                fullWidth
                multiline
                rows={12}
                error={Boolean(formik?.errors?.wifiMessage?.message)}
                helperText={formik?.errors?.wifiMessage?.message}
                maxLength={1000}
                InputProps={{
                    endAdornment: (
                        <StyledInputAdornment
                            position="end"
                            sx={{
                                top: pxToRem(-125),
                                position: 'relative'
                            }}
                        >
                            {getCharacterLimit(
                                1000,
                                formik.values.wifiMessage.message
                            )}
                        </StyledInputAdornment>
                    )
                }}
            />
            <FormGroup sx={{ float: 'right' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={formik.values.networkCredentials.enabled}
                            {...formik.getFieldProps(
                                'networkCredentials.enabled'
                            )}
                        />
                    }
                    label={
                        <Typography sx={styles.checkboxLabel}>
                            Enable
                        </Typography>
                    }
                />
            </FormGroup>
            <Grid container columnSpacing={2}>
                <Grid item xs={6}>
                    <TextField
                        disabled={!formik.values.networkCredentials.enabled}
                        id="outlined-basic"
                        label="Network Name"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        {...formik.getFieldProps(
                            'networkCredentials.networkName'
                        )}
                        value={formik.values.networkCredentials.networkName}
                        placeholder={cmpTexts.wifiGenerator.namePlaceholder}
                        fullWidth
                        error={Boolean(
                            formik?.errors?.networkCredentials?.networkName
                        )}
                        helperText={
                            formik?.errors?.networkCredentials?.networkName
                        }
                        maxLength={20}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled={!formik.values.networkCredentials.enabled}
                        id="outlined-basic"
                        label="Network Password"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        {...formik.getFieldProps(
                            'networkCredentials.networkPassword'
                        )}
                        value={formik.values.networkCredentials.networkPassword}
                        placeholder={cmpTexts.wifiGenerator.passwordPlaceholder}
                        fullWidth
                        error={Boolean(
                            formik?.errors?.networkCredentials?.networkPassword
                        )}
                        helperText={
                            formik?.errors?.networkCredentials?.networkPassword
                        }
                        maxLength={20}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default WifiGenerator;
