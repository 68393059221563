import { AlertProps, Portal } from '@mui/material';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import React, { useState } from 'react';

export interface SnackBarHOCProps {
    snackbarShowMessage: (
        message: string,
        severity?: AlertProps['severity'],
        duration?: number
    ) => void;
}

// eslint-disable-next-line react/display-name
export function withSnackbar<P>(
    WrappedComponent: React.ComponentType<P & SnackBarHOCProps>
) {
    return (props: P) => {
        const [open, setOpen] = useState(false);
        const [message, setMessage] = useState('');
        const [duration, setDuration] = useState(2000);
        const [severity, setSeverity] =
            useState<AlertProps['severity']>(
                'success'
            ); /** error | warning | info */

        const showMessage = (
            message: string,
            severity: AlertProps['severity'] = 'success',
            duration = 2000
        ) => {
            setMessage(message);
            setSeverity(severity);
            setDuration(duration);
            setOpen(true);
        };

        const handleClose = (
            event?: React.SyntheticEvent | Event,
            reason?: string
        ) => {
            if (reason === 'clickaway') {
                return;
            }

            setOpen(false);
        };

        return (
            <>
                <WrappedComponent
                    {...props}
                    snackbarShowMessage={showMessage}
                />
                <Portal>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        autoHideDuration={duration}
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Slide}
                    >
                        <Alert
                            variant="filled"
                            onClose={handleClose}
                            severity={severity}
                        >
                            {message}
                        </Alert>
                    </Snackbar>
                </Portal>
            </>
        );
    };
}
