import { Button, ButtonProps, SxProps } from '@mui/material';
import React, { CSSProperties } from 'react';
import { pxToRem } from '../utils/stylesUtils';

export const enum ButtonVariation {
    TEXT = 'text',
    CONTAINED = 'contained',
    OUTLINED = 'outlined'
}

export const enum ButtonSize {
    LARGE = 'large',
    MEDIUM = 'medium',
    SMALL = 'small',
    XSMALL = 'xsmall'
}

interface CustomButtonProps extends ButtonProps {
    buttonVariant?: ButtonVariation;
    buttonSize?: ButtonSize;
}

function CutomButton(props: CustomButtonProps) {
    const {
        children,
        buttonVariant = ButtonVariation.TEXT,
        disabled = false,
        buttonSize = ButtonSize.LARGE,
        sx,
        ...rest
    } = props;

    let mWidth = pxToRem(180);
    let height = pxToRem(44);
    let fontSize = pxToRem(16);
    switch (buttonSize) {
        case ButtonSize.LARGE:
            mWidth = pxToRem(180);
            break;
        case ButtonSize.MEDIUM:
            mWidth = pxToRem(160);
            break;
        case ButtonSize.SMALL:
            mWidth = pxToRem(120);
            break;
        case ButtonSize.XSMALL:
            mWidth = pxToRem(64);
            height = pxToRem(28);
            fontSize = pxToRem(12);
            break;
    }

    let style: CSSProperties & SxProps = {
        minWidth: mWidth,
        height,

        //textTransform: 'capitalize',
        fontSize
    };

    switch (buttonVariant) {
        case ButtonVariation.TEXT:
            style = {
                ...style,
                color: '#171725'
            };
            break;

        case ButtonVariation.CONTAINED:
            style = {
                ...style,
                backgroundColor: `#F01446`,
                color: `#fff`,
                fontWeight: `bold`
            };
            break;

        case ButtonVariation.OUTLINED:
            style = {
                ...style,
                color: 'white',
                borderColor: 'white'
            };
            break;
    }

    if (disabled) {
        switch (buttonVariant) {
            case ButtonVariation.TEXT:
                style = {
                    ...style,
                    opacity: 0.5
                };
                break;

            case ButtonVariation.CONTAINED:
                style = {
                    ...style,
                    opacity: 0.5,
                    boxShadow: `0 2px 8px 0 rgba(82,82,89,0.6)`
                };
        }
    }

    //@ts-ignore
    style = {
        ...style,
        ...sx
    };

    return (
        <Button
            {...rest}
            disabled={disabled}
            style={style}
            variant={buttonVariant}
        >
            {children}
        </Button>
    );
}

export default CutomButton;
