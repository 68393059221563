import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router';
import CMPRouter from './router/cmpRouter';

declare global {
    interface Window {
        newrelic?: any;
    }
}

declare global {
    interface Window {
        newrelic?: any;
    }
}

function App() {
    useEffect(() => {
        window.newrelic?.setCustomAttribute(
            'environment',
            process.env.REACT_APP_ENVIRONMENT
        );
    }, []);
    return <RouterProvider router={CMPRouter} />;
}

export default App;
