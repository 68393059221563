import { Auth } from 'aws-amplify';
import axios from 'axios';
import LogoutBroadcastChannel from '../auth/logoutBroadcastChannel';
import { fetchMaintenanceStatus } from '../redux/maintenance/action';
import { store } from '../redux/store';
import ApiResponseCode from './apiResponseCode';
const axiosInstance = axios.create();

async function getToken() {
    const auth = await Auth.currentSession();
    console.log('jwtToken exp time - ', auth.getIdToken().getExpiration());
    return auth.getIdToken().getJwtToken();
}

// Add a request interceptor
axiosInstance.interceptors.request.use(
    async function (config: any) {
        // Do something before request is sent

        const jwtToken = await getToken();
        config.headers['Authorization'] = `bearer ${jwtToken}`;

        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response.data;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (
            error === ApiResponseCode.NO_CURRENT_USER ||
            error?.code === ApiResponseCode.REFRESH_TOKEN_EXPIRED ||
            error.response?.status === 401
        ) {
            LogoutBroadcastChannel.triggerLogout();
            return;
        } else if (error.response?.status === 503) {
            store.dispatch(fetchMaintenanceStatus());
            return;
        }
        return Promise.reject(
            error?.response?.data ? error?.response?.data : error
        );
    }
);

export default axiosInstance;
