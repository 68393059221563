import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import FullScreenCircularLoader from '../../components/fullScreenCircularLoader';
import { SnackBarHOCProps, withSnackbar } from '../../components/snackbar';
import { useUrlParams } from '../../hooks/useParam';
import CMPTexts from '../../locale/en';
import { ApiResponseState, LoadingStatus } from '../../redux/common/state';
import { setElementSatus } from '../../redux/element/action';
import {
    selectCreateElementStatus,
    selectSaveElementStatus
} from '../../redux/element/reducer';
import {
    deleteElementResponse,
    fetchElements,
    resetElimentListLoadingStatus
} from '../../redux/elementsList/action';
import {
    deleteElementStatus,
    selectElements,
    selectElementsError,
    selectElementsLoadingStatus
} from '../../redux/elementsList/reducer';
import { replaceBreadCrumb } from '../../redux/login/action';
import { selectBreadCrumb } from '../../redux/login/reducer';
import { BreadCrumb } from '../../redux/login/model';
import { enableScroll } from '../../utils/scrollUtil';
import ElementsAccordion from './elementsAccordion';
import { pxToRem } from '../../utils/stylesUtils';

const ElementsContainer = styled('div')(() => ({
    flex: 1,
    overflow: 'auto',
    marginBottom: 50,
    marginLeft: pxToRem(43)
}));

const ManageElementsRow = styled(Typography)(() => ({
    fontSize: '1.6rem',
    fontWeight: 500,
    textAlign: 'start',
    marginTop: 20
}));

const DescriptionContainer = styled('div')(() => ({
    display: 'flex',
    marginBottom: '2vh',
    marginTop: 5
}));

const InfoOutlinedIconStyle = styled('div')(() => ({
    marginTop: 1
}));

const DescriptionText = styled('div')(() => ({
    marginLeft: 10,
    fontSize: '1.04rem',
    fontWeight: 500
}));
const Elements = (props: SnackBarHOCProps) => {
    const { snackbarShowMessage } = props;
    const { property } = useUrlParams();
    const location = useLocation();
    const loader = useSelector(selectElementsLoadingStatus);
    const error = useSelector(selectElementsError);
    const elementsData = useSelector(selectElements);
    const dispatch = useDispatch();
    const createElementStatus = useSelector(selectCreateElementStatus);
    const saveElementStatus = useSelector(selectSaveElementStatus);
    const elementDeleteStatus = useSelector(deleteElementStatus);
    const breadCrumb = useSelector(selectBreadCrumb);

    useEffect(() => {
        console.log('fetching elements list...');
        dispatch(fetchElements(property));
        if (breadCrumb.length >= 2) {
            dispatch(replaceBreadCrumb(breadCrumb.slice(0, 2)));
        } else {
            //if user directly use url and land on this page
            const breadCrumb: BreadCrumb[] = [];

            breadCrumb.push({
                name: 'Properties',
                link: '/properties',
                state: {}
            });
            breadCrumb.push({
                name: 'Property',
                link: `/properties/${property}`,
                state: {}
            });
            dispatch(replaceBreadCrumb(breadCrumb));
        }
    }, []);

    useEffect(() => {
        enableScroll();
        if (loader === LoadingStatus.DONE) {
            console.log('successfully fetched elements list');
            if (
                createElementStatus === LoadingStatus.DONE ||
                saveElementStatus === LoadingStatus.DONE
            ) {
                if (location.state?.widgetType)
                    snackbarShowMessage(
                        `${location.state?.widgetType} saved successfully. Please update the same in property details page`,
                        'success',
                        3000
                    );
                dispatch(setElementSatus('createElementStatus'));
                dispatch(setElementSatus('saveElementStatus'));
            }
        } else if (loader === LoadingStatus.FAILED) {
            dispatch(resetElimentListLoadingStatus());
            snackbarShowMessage(error, 'error');
        }
    }, [loader]);

    useEffect(() => {
        if (elementDeleteStatus === ApiResponseState.SUCCESS) {
            snackbarShowMessage(
                CMPTexts.deleteElementMessage.successDeleteMeassge
            );
            dispatch(deleteElementResponse(ApiResponseState.NONE));
            dispatch(fetchElements(property));
        } else if (elementDeleteStatus === ApiResponseState.FAILED) {
            snackbarShowMessage(
                CMPTexts.deleteElementMessage.errorDeleteMessage,
                'error'
            );
            dispatch(deleteElementResponse(ApiResponseState.NONE));
        }
    }, [elementDeleteStatus, snackbarShowMessage, dispatch, property]);

    return (
        <ElementsContainer>
            {loader === LoadingStatus.INITIATED && <FullScreenCircularLoader />}
            <>
                <ManageElementsRow>
                    {CMPTexts.configureElement.heading}
                </ManageElementsRow>
                <DescriptionContainer>
                    <InfoOutlinedIconStyle>
                        <InfoOutlinedIcon />
                    </InfoOutlinedIconStyle>
                    <DescriptionText>
                        {CMPTexts.configureElement.pageDescription}
                    </DescriptionText>
                </DescriptionContainer>
                <ElementsAccordion elementsData={elementsData} />
            </>
        </ElementsContainer>
    );
};

export default withSnackbar(Elements);
