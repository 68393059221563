import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid, Link, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonVariation } from '../../components/button';
import FullScreenCircularLoader from '../../components/fullScreenCircularLoader';
import CMPBackground from '../../components/cmpBackground';
import TextField from '../../components/textField';
import CMPTexts from '../../locale/en';
import { forgotPassword, setForgotPassword } from '../../redux/login/action';
import { ForgotPasswordStatus } from '../../redux/login/model';
import { forgotPasswordValidationSchema } from '../../utils/loginUtils';
import { SnackBarHOCProps, withSnackbar } from '../../components/snackbar';
import {
    selectError,
    selectForgotPasswordStatus,
    selectLoadingStatus
} from '../../redux/login/reducer';

const LoginContainer = styled('div')(() => ({
    backgroundColor: 'rgb(23,23,37)',
    height: '100vh',
    width: '100%'
}));

const CustomPaper = styled(Paper)(() => ({
    width: '30vw',
    marginTop: '30vh'
}));

const PaperHeadingBox = styled(Box)(() => ({
    textAlign: 'left',
    fontWeight: 1500,
    fontSize: 28,
    marginBottom: 10,
    marginTop: 12,
    marginLeft: '2vw'
}));

const PaperBodyBox = styled(Box)(() => ({
    width: '26.5vw',
    fontSize: 13,
    marginLeft: '2vw'
}));

const CustomTextField = styled(TextField)(() => ({
    marginTop: 16,
    width: '26vw'
}));

const CustomButton = styled(Button)(() => ({
    width: '35%',
    marginTop: 25,
    backgroundColor: 'rgb(255,163,0)',
    fontSize: 14,
    marginBottom: 40
}));

const ArrowBack = styled(ArrowBackIcon)(() => ({
    fontSize: 20,
    marginRight: 5
}));

const LinkComponent = styled(Link)(() => ({
    fontSize: 13,
    '&:hover': { cursor: 'pointer' },
    display: 'flex',
    flex: 1,
    alignItems: 'center'
}));

const BackToLoginBox = styled('div')(() => ({
    textAlign: 'left',
    fontSize: 12,
    marginLeft: '2vw',
    marginTop: 30
}));

const ForgotPassword = (props: SnackBarHOCProps) => {
    const { snackbarShowMessage } = props;

    const dispatch = useDispatch();
    const loadingStatus = useSelector(selectLoadingStatus);
    const forgotPasswordStatus = useSelector(selectForgotPasswordStatus);
    const navigate = useNavigate();
    const forgotPasswordError = useSelector(selectError);

    useEffect(() => {
        dispatch(setForgotPassword());
    }, []);

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: forgotPasswordValidationSchema,
        onSubmit: () => {}
    });

    useEffect(() => {
        dispatch(setForgotPassword()); //To prevent loader to execute just after landing on this page
        if (
            forgotPasswordStatus === ForgotPasswordStatus.FORGOT_PASSWORD_DONE
        ) {
            navigate(`/changePassword?email=${formik.values.email}`);
        } else if (
            forgotPasswordStatus === ForgotPasswordStatus.FORGOT_PASSWORD_FAILED
        ) {
            snackbarShowMessage(forgotPasswordError, 'error');
        }
    }, [forgotPasswordStatus]);

    const handleClick = () => {
        navigate(-1);
    };

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        dispatch(forgotPassword(formik.values.email));
    };

    return (
        <LoginContainer>
            {loadingStatus && <FullScreenCircularLoader />}
            <CMPBackground>
                <CustomPaper elevation={3}>
                    <form onSubmit={handleSubmit}>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={12}>
                                <BackToLoginBox>
                                    <LinkComponent
                                        color="inherit"
                                        onClick={handleClick}
                                        underline="none"
                                    >
                                        <ArrowBack />
                                        {CMPTexts.forgotPassword.backToLogin}
                                    </LinkComponent>
                                </BackToLoginBox>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={12}>
                                <PaperHeadingBox>
                                    {CMPTexts.forgotPassword.paperHeading}
                                </PaperHeadingBox>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={12}>
                                <PaperBodyBox>
                                    {CMPTexts.forgotPassword.paperBody}
                                </PaperBodyBox>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid
                                item
                                xs={12}
                                container
                                justifyContent="center"
                            >
                                <CustomTextField
                                    size="small"
                                    required
                                    id="outlined-basic"
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.email)}
                                    helperText={formik.errors.email}
                                />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center">
                            <Grid
                                item
                                xs={12}
                                container
                                justifyContent="center"
                            >
                                <CustomButton
                                    type="submit"
                                    buttonVariant={ButtonVariation.CONTAINED}
                                    disabled={!(formik.isValid && formik.dirty)}
                                >
                                    {CMPTexts.forgotPassword.button}
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </form>
                </CustomPaper>
            </CMPBackground>
        </LoginContainer>
    );
};

export default withSnackbar(ForgotPassword);
