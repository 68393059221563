import { Visibility, VisibilityOff } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import { Grid, InputAdornment, Paper } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LogoutBroadcastChannel from '../../auth/logoutBroadcastChannel';
import Button, { ButtonVariation } from '../../components/button';
import CMPBackground from '../../components/cmpBackground';
import FullScreenCircularLoader from '../../components/fullScreenCircularLoader';
import { SnackBarHOCProps, withSnackbar } from '../../components/snackbar';
import TextField from '../../components/textField';
import useQuery from '../../hooks/useQuery';
import CMPTexts from '../../locale/en';
import { setLoginStatus, userLogin } from '../../redux/login/action';
import { LoginStatus } from '../../redux/login/model';
import { selectError, selectLoginstatus } from '../../redux/login/reducer';
import { emailVerificationValidationSchema } from '../../utils/loginUtils';

const LoginContainer = styled('div')(() => ({
    backgroundColor: 'rgb(23,23,37)',
    height: '100vh',
    width: '100%'
}));

const CustomPaper = styled(Paper)(() => ({
    width: '30vw',
    height: 'auto',
    marginTop: '8vh'
}));

const PaperHeadingBox = styled(Box)(() => ({
    textAlign: 'left',
    fontSize: 28,
    marginTop: 20
}));

const PaperBodyBox = styled(Box)(() => ({
    textAlign: 'left',
    fontSize: 12,
    marginTop: 7
}));

const CustomTextField = styled(TextField)(() => ({
    marginTop: 20
}));

const CustomButton = styled(Button)(() => ({
    marginTop: 25,
    backgroundColor: 'rgb(255,163,0)',
    width: '35%',
    fontSize: 14,
    marginBottom: 30,
    color: 'white',
    p: 1
}));

const PasswordConditionBox = styled(Box)(() => ({
    marginTop: 10,
    textAlign: 'left',
    fontSize: 12
}));

const DoneIconBox = styled(Box)(() => ({
    textAlign: 'left',
    fontSize: 12
}));

const DoneIconComponent = styled(DoneIcon)(() => ({
    fontSize: 12,
    verticalAlign: 'middle'
}));

const EmailVerification = (props: SnackBarHOCProps) => {
    const { snackbarShowMessage } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const query = useQuery();
    const email: string | null = query.get('email');
    const loadingStatus: string = useSelector(selectLoginstatus);
    const emailVerificationError = useSelector(selectError);
    const [showTempPassword, setShowTempPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);

    useEffect(() => {
        LogoutBroadcastChannel.triggerLogout();
        dispatch(setLoginStatus(LoginStatus.NONE));
    }, []);

    useEffect(() => {
        if (loadingStatus === LoginStatus.NEW_PASSWORD_SET_SUCCESSFULLY) {
            dispatch(setLoginStatus(LoginStatus.NONE));
            console.log('User Verified, redirecting to email verified page...');
            navigate('/emailVerified');
        } else if (loadingStatus === LoginStatus.NEW_PASSWORD_FAILED) {
            snackbarShowMessage(emailVerificationError, 'error');
            console.log('Could not verify user - ', emailVerificationError);
        }
    }, [loadingStatus]);

    const formik = useFormik({
        initialValues: {
            email: email ?? '',
            tempPassword: '',
            password: '',
            repeatPassword: ''
        },
        validationSchema: emailVerificationValidationSchema,
        onSubmit: () => {}
    });

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        dispatch(
            userLogin({
                email: formik.values.email,
                password: formik.values.tempPassword,
                newPassword: formik.values.password
            })
        );
        console.log(`verifying user ${email}...`);
    };

    return (
        <LoginContainer>
            {(loadingStatus === LoginStatus.LOGGING_IN ||
                loadingStatus === LoginStatus.NEW_PASSWORD_REQUIRED) && (
                <FullScreenCircularLoader />
            )}
            {/* <Box sx={style.topMargin} /> */}
            <CMPBackground>
                <CustomPaper elevation={3}>
                    <form onSubmit={handleSubmit}>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={1}></Grid>
                            <Grid item xs={9}>
                                <PaperHeadingBox>
                                    {CMPTexts.emailVerification.paperHeading}
                                </PaperHeadingBox>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={1}></Grid>
                            <Grid item xs={9}>
                                <PaperBodyBox>
                                    {CMPTexts.emailVerification.paperBody}
                                </PaperBodyBox>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-start">
                            <Grid xs={1}></Grid>
                            <Grid xs={10}>
                                <CustomTextField
                                    disabled={Boolean(email)}
                                    defaultValue={formik.values.email}
                                    size="small"
                                    id="outlined-basic"
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-start">
                            <Grid xs={1}></Grid>
                            <Grid xs={10}>
                                <CustomTextField
                                    size="small"
                                    required
                                    type={
                                        showTempPassword ? 'text' : 'password'
                                    }
                                    id="outlined-basic"
                                    label={
                                        CMPTexts.emailVerification
                                            .textFieldTempPass
                                    }
                                    variant="outlined"
                                    fullWidth
                                    {...formik.getFieldProps('tempPassword')}
                                    error={Boolean(formik.errors.tempPassword)}
                                    helperText={formik.errors.tempPassword}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() =>
                                                        setShowTempPassword(
                                                            !showTempPassword
                                                        )
                                                    }
                                                >
                                                    {!showTempPassword ? (
                                                        <Visibility fontSize="small" />
                                                    ) : (
                                                        <VisibilityOff fontSize="small" />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-start">
                            <Grid xs={1}></Grid>
                            <Grid xs={10}>
                                <CustomTextField
                                    size="small"
                                    required
                                    type={showPassword ? 'text' : 'password'}
                                    id="outlined-basic"
                                    label={
                                        CMPTexts.emailVerification
                                            .textFieldNewPass
                                    }
                                    variant="outlined"
                                    fullWidth
                                    {...formik.getFieldProps('password')}
                                    error={Boolean(formik.errors.password)}
                                    helperText={formik.errors.password}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() =>
                                                        setShowPassword(
                                                            !showPassword
                                                        )
                                                    }
                                                >
                                                    {!showPassword ? (
                                                        <Visibility fontSize="small" />
                                                    ) : (
                                                        <VisibilityOff fontSize="small" />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-start">
                            <Grid xs={1}></Grid>
                            <Grid xs={10}>
                                <CustomTextField
                                    size="small"
                                    required
                                    type={
                                        showRepeatPassword ? 'text' : 'password'
                                    }
                                    id="outlined-basic"
                                    label={
                                        CMPTexts.emailVerification
                                            .textFieldRepeatNewPass
                                    }
                                    variant="outlined"
                                    fullWidth
                                    {...formik.getFieldProps('repeatPassword')}
                                    error={Boolean(
                                        formik.errors.repeatPassword
                                    )}
                                    helperText={formik.errors.repeatPassword}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() =>
                                                        setShowRepeatPassword(
                                                            !showRepeatPassword
                                                        )
                                                    }
                                                >
                                                    {!showRepeatPassword ? (
                                                        <Visibility fontSize="small" />
                                                    ) : (
                                                        <VisibilityOff fontSize="small" />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={1}></Grid>
                            <Grid item xs={10}>
                                <PasswordConditionBox>
                                    {
                                        CMPTexts.emailVerification
                                            .passwordConditionsTitle
                                    }
                                </PasswordConditionBox>
                                <DoneIconBox>
                                    <DoneIconComponent />
                                    {
                                        CMPTexts.emailVerification
                                            .passwordConditions[0]
                                    }
                                </DoneIconBox>
                                <DoneIconBox>
                                    <DoneIconComponent />
                                    {
                                        CMPTexts.emailVerification
                                            .passwordConditions[1]
                                    }
                                </DoneIconBox>
                                <DoneIconBox>
                                    <DoneIconComponent />
                                    {
                                        CMPTexts.emailVerification
                                            .passwordConditions[2]
                                    }
                                </DoneIconBox>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center">
                            <CustomButton
                                type="submit"
                                buttonVariant={ButtonVariation.CONTAINED}
                                disabled={!(formik.isValid && formik.dirty)}
                            >
                                {CMPTexts.emailVerification.button}
                            </CustomButton>
                        </Grid>
                    </form>
                </CustomPaper>
            </CMPBackground>
        </LoginContainer>
    );
};

export default withSnackbar(EmailVerification);
