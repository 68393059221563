import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ReactComponent as MapsHomeWorkIcon } from '../assets/svg/property.svg';
import { ReactComponent as LaptopIcon } from '../assets/svg/platform.svg';
import { ReactComponent as OrgIcon } from '../assets/svg/organization.svg';
import { ReactComponent as AdminPanelSettingsIcon } from '../assets/svg/groups.svg';
import { ReactComponent as PersonIcon } from '../assets/svg/account.svg';
import { ReactComponent as ActivityMonitorIcon } from '../assets/svg/activityMonitoring.svg';
import IconStylizer from './iconStylizer';
import {
    Box,
    CssBaseline,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    Menu,
    MenuItem,
    Toolbar,
    Typography
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { version } from '../../package.json';
import CMPLogo from '../components/cmpLogo';
import LogoutBroadcastChannel from '../auth/logoutBroadcastChannel';
import { LoadingStatus } from '../redux/common/state';
import { selectUserName, selectUserRole } from '../redux/login/reducer';
import { resetLoader } from '../redux/maintenance/action';
import {
    selectMaintenanceLoadingStatus,
    selectMaintenanceMessage,
    selectMaintenanceStatus
} from '../redux/maintenance/reducer';
import {
    selectActivityMonitoringAccessible,
    selectGeneralSectionAccessible,
    selectOrganizationModuleAccessible,
    selectPropertiesModuleAccessible,
    selectUserModuleAccessible
} from '../redux/userOperations/reducer';
import { UserRole } from '../redux/users/model';
import MaintenanceModeSwitch from '../screens/maintenance/maintenanceModeSwitch';
import { pxToRem } from '../utils/stylesUtils';
import { getRoleDisplayName } from '../utils/userManagementUtil';
import FullScreenCircularLoader from './fullScreenCircularLoader';
import { SnackBarHOCProps, withSnackbar } from './snackbar';

const enum URL {
    properties = 'Properties',
    organizations = 'Org',
    elements = 'Elements',
    assets = 'Assets',
    users = 'Users',
    generalSection = 'Platform',
    activity = 'Activity'
}

export const DRAWER_CLOSE_WIDTH = 105;

const VersionDiv = styled('div')(() => ({
    color: 'white',
    padding: `${pxToRem(8)} ${pxToRem(18)}`
}));

const styles = {
    container: { display: 'flex' },
    title: { flexGrow: 1 },
    drawer: {
        display: 'flex',
        alignItems: 'center'
    },
    white: { color: '#f5f5f6' },
    drawerContentContainer: {
        flex: 1,
        marginTop: pxToRem(108),
        paddingLeft: pxToRem(12),
        paddingRight: 0,
        borderRightWidth: 0
    },
    primaryColor: {
        color: '#F01446'
    }
};

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const MainContent = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
    marginTop: pxToRem(64),
    minHeight: '100vh',
    width: `calc(100% - ${DRAWER_CLOSE_WIDTH}px)`
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    })
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open'
})(({ theme }) => ({
    width: DRAWER_CLOSE_WIDTH,
    '& .MuiDrawer-paper': {
        backgroundColor: '#171725',
        overflowX: 'hidden',
        width: DRAWER_CLOSE_WIDTH
    }
}));

const MainNavList = styled(List)(() => ({
    '&.selected': {
        '.list-text': {
            color: '#F01446'
        },
        backgroundColor: 'rgb(246, 246, 246)'
    },
    '.list-text': {
        color: '#ffffff',
        fontSize: pxToRem(14)
    },
    margin: pxToRem(10),
    borderRadius: pxToRem(5),
    marginLeft: '0'
}));

const url = (text: string) => {
    if (text === URL.elements) {
        return '/elements';
    } else if (text === URL.assets) {
        return '/assets';
    } else if (text === URL.organizations) {
        return '/organizations';
    } else if (text === URL.users) {
        return '/users';
    } else if (text === URL.generalSection) {
        return '/generalSection';
    } else if (text === URL.activity) {
        return '/activity';
    }
    return '/properties';
};

function CustomIconList(props: any) {
    const navigate = useNavigate();
    const { text } = props;

    return (
        <ListItem
            button
            key={props.text}
            onClick={() => {
                navigate(`${url(text)}`);
            }}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                color: '#ffffff'
            }}
        >
            <ListItemIcon sx={{ display: 'flex', justifyContent: 'center' }}>
                {props.children}
            </ListItemIcon>
            <Box className="list-text">{props.text}</Box>
        </ListItem>
    );
}

function NavDrawer(props: any & SnackBarHOCProps) {
    const { snackbarShowMessage } = props as SnackBarHOCProps;
    // const theme = useTheme();
    const dispatch = useDispatch();

    const userRole = useSelector(selectUserRole);
    const userName = useSelector(selectUserName);
    const isUserModuleAllowed = useSelector(selectUserModuleAccessible);
    const isUnderMaintenance = useSelector(selectMaintenanceStatus);
    const isGeneralSectionModuleAllowed = useSelector(
        selectGeneralSectionAccessible
    );
    const isActivityMonitorAllowed = useSelector(
        selectActivityMonitoringAccessible
    );

    const isPropertyModuleAllowed = useSelector(
        selectPropertiesModuleAccessible
    );
    const isOrganizationAllowed = useSelector(
        selectOrganizationModuleAccessible
    );

    const isSuperAdmin = userRole === UserRole.SuperAdmin;
    const maintenanceModeLoader = useSelector(selectMaintenanceLoadingStatus);
    const maintenanceModeMessage = useSelector(selectMaintenanceMessage);

    React.useEffect(() => {
        if (maintenanceModeLoader === LoadingStatus.DONE) {
            if (maintenanceModeMessage)
                snackbarShowMessage(maintenanceModeMessage);
            dispatch(resetLoader());
        } else if (maintenanceModeLoader === LoadingStatus.FAILED) {
            snackbarShowMessage(maintenanceModeMessage, 'error');
            dispatch(resetLoader());
        }
    }, [maintenanceModeLoader]);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setShowLoader(true);
        LogoutBroadcastChannel.triggerLogout();
    };

    const handleLogoClick = () => {
        navigate('/properties');
    };
    const [showLoader, setShowLoader] = React.useState(false);

    return (
        <Box sx={styles.container}>
            {showLoader && <FullScreenCircularLoader />}
            <CssBaseline />
            <AppBar position="fixed" color={'secondary'}>
                <Toolbar>
                    <IconButton onClick={handleLogoClick}>
                        <CMPLogo />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={styles.title}
                    />
                    {isSuperAdmin && <MaintenanceModeSwitch />}
                    <Box>
                        <IconButton
                            sx={{ pointerEvents: 'none' }}
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="false"
                            color="inherit"
                        >
                            <PersonIcon />
                        </IconButton>
                        {userName} | {getRoleDisplayName(userRole)}
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <ArrowDropDownIcon />
                        </IconButton>
                    </Box>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            {(!isUnderMaintenance || isSuperAdmin) && (
                <Drawer
                    variant="permanent"
                    color={'secondary'}
                    style={styles.drawer}
                >
                    <Box style={styles.drawerContentContainer}>
                        {isPropertyModuleAllowed && (
                            <MainNavList
                                color={'secondary'}
                                className={
                                    pathname.split('/').includes('properties')
                                        ? 'selected'
                                        : ''
                                }
                            >
                                <CustomIconList text={URL.properties}>
                                    <IconStylizer
                                        color={
                                            pathname
                                                .split('/')
                                                .includes('properties')
                                                ? '#F01446'
                                                : '#ffffff'
                                        }
                                    >
                                        <MapsHomeWorkIcon />
                                    </IconStylizer>
                                </CustomIconList>
                            </MainNavList>
                        )}
                        {isUserModuleAllowed && (
                            <MainNavList
                                color={'secondary'}
                                className={
                                    pathname.split('/').includes('users')
                                        ? 'selected'
                                        : ''
                                }
                            >
                                <CustomIconList text="Users">
                                    <IconStylizer
                                        color={
                                            pathname
                                                .split('/')
                                                .includes('users')
                                                ? '#F01446'
                                                : '#FFFFFF'
                                        }
                                        height="19px"
                                    >
                                        <AdminPanelSettingsIcon />
                                    </IconStylizer>
                                </CustomIconList>
                            </MainNavList>
                        )}
                        {isOrganizationAllowed && (
                            <MainNavList
                                color={'secondary'}
                                className={
                                    pathname
                                        .split('/')
                                        .includes('organizations')
                                        ? 'selected'
                                        : ''
                                }
                            >
                                <CustomIconList text={URL.organizations}>
                                    <IconStylizer
                                        color={
                                            pathname
                                                .split('/')
                                                .includes('organizations')
                                                ? '#F01446'
                                                : '#FFFFFF'
                                        }
                                        height="18px"
                                    >
                                        <OrgIcon />
                                    </IconStylizer>
                                </CustomIconList>
                            </MainNavList>
                        )}
                        {isGeneralSectionModuleAllowed && (
                            <MainNavList
                                color={'secondary'}
                                // sx={styleOnSelection('generalSection')}
                                className={
                                    pathname
                                        .split('/')
                                        .includes('generalSection')
                                        ? 'selected'
                                        : ''
                                }
                            >
                                <CustomIconList text={URL.generalSection}>
                                    <IconStylizer
                                        color={
                                            pathname
                                                .split('/')
                                                .includes('generalSection')
                                                ? '#F01446'
                                                : '#FFFFFF'
                                        }
                                    >
                                        <LaptopIcon />
                                    </IconStylizer>
                                </CustomIconList>
                            </MainNavList>
                        )}
                        {isActivityMonitorAllowed && (
                            <MainNavList
                                color="secondary"
                                className={
                                    pathname.split('/').includes('activity')
                                        ? 'selected'
                                        : ''
                                }
                            >
                                <CustomIconList text={URL.activity}>
                                    <IconStylizer
                                        color={
                                            pathname
                                                .split('/')
                                                .includes('activity')
                                                ? '#F01446'
                                                : '#FFFFFF'
                                        }
                                    >
                                        <ActivityMonitorIcon className="main-nav-icon-design" />
                                    </IconStylizer>
                                </CustomIconList>
                            </MainNavList>
                        )}
                    </Box>
                    <VersionDiv>{version}</VersionDiv>
                </Drawer>
            )}
            <MainContent
                sx={{
                    background: pathname.split('/').includes('activity')
                        ? '#F5F5F6'
                        : 'inherit'
                }}
            >
                {props.children}
            </MainContent>
        </Box>
    );
}

export default withSnackbar(NavDrawer);
