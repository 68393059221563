import { API_URL } from '../../api/apiConstants';
import axios from '../../api/axios';
import { ActionPayload } from '../types';
import { Evove2ConfigRequestBody } from './model';

export function fetchEvolve2FieldConfig(data: ActionPayload<string>) {
    return axios.get(
        API_URL.evolve2 + `/${data.payload}/evolve2/fieldsAndValues`
    );
}

export function updateEvolve2Status(
    data: ActionPayload<Evove2ConfigRequestBody>
) {
    const body = {
        evolve2_enabled: data.payload.evolve2_enabled,
        property_id: data.payload.property_id
    };
    return axios.post(
        API_URL.evolve2 + `/${data.payload.property_id}/evolve2/update`,
        body
    );
}

export function updateEvolve2Fields(
    body: ActionPayload<Evove2ConfigRequestBody>
) {
    console.log('updatePropertyMultiFieldValue ', body.payload.fields);
    const payload = {
        fields: body.payload.fields,
        modified_at: Date.now()
    };

    return axios.post(
        API_URL.multiFieldUpdate + `/${body.payload.property_id}/fields`,
        payload
    );
}
