import { PlayCircleOutline } from '@mui/icons-material';
import { Box, LinearProgress, styled, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { ReactComponent as DeleteIcon } from '../../assets/svg/trashBin.svg';
import CMPErrorHelperText from '../../components/cmpErrorHelperText';
import CMPOutlinedTextField from '../../components/cmpOutlinedTextField';
import { DisableableBox } from '../../components/disableableBox';
import IconStylizer from '../../components/iconStylizer';
import cmpTexts from '../../locale/en';
import { AssetStatus } from '../../redux/assets/actionPayload';
import { pxToRem } from '../../utils/stylesUtils';
import { ALLOWED_FILE_TYPES, AssetDetails } from './uploadAssetModal';

const VideoOverlay = styled(PlayCircleOutline)(() => ({
    position: 'absolute',
    left: 120,
    width: pxToRem(65),
    height: '100%',
    opacity: '40%',
    color: 'white',
    zIndex: 1
}));

const AssetDetailsContainer = styled(Box, {
    shouldForwardProp: (prop: string) => prop !== 'isDarkMode'
})<{ isDarkMode: boolean }>(({ isDarkMode }) => ({
    background: isDarkMode ? 'rgb(196, 196, 202)' : 'rgba(246, 245, 246)',
    display: 'flex',
    width: '100%',
    height: '266px',
    maxWidth: pxToRem(306),
    borderRadius: pxToRem(8),
    padding: pxToRem(16),
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'center'
}));

const MultiAssetDetailsContainer = styled(Box, {
    shouldForwardProp: (prop: string) => prop !== 'isDarkMode'
})<{ isDarkMode: boolean }>(({ isDarkMode }) => ({
    background: isDarkMode ? 'rgb(196, 196, 202)' : 'rgba(246, 245, 246)',
    display: 'flex',
    width: '100%',
    height: '100%',
    borderRadius: pxToRem(8),
    padding: pxToRem(8),
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'start',
    overflowX: 'hidden',
    overflowY: 'scroll'
}));

const AssetNameContainer = styled(Box)(() => ({
    display: 'flex',
    gap: pxToRem(8),
    width: '100%'
}));

const MultiDeleteIcon = styled(DeleteIcon)(() => ({
    cursor: 'pointer',
    flexShrink: 0,
    marginRight: pxToRem(16)
}));

const SingleDeleteIcon = styled(DeleteIcon)(() => ({
    cursor: 'pointer',
    position: 'absolute',
    top: pxToRem(24),
    right: pxToRem(24),
    filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 16, 1))'
}));

const AssetUploadProgressBar = (props: {
    percent: number;
    status: AssetStatus;
}): React.ReactElement => {
    const { percent, status } = props;

    return (
        <Box
            sx={{
                width: '95%',
                display: 'flex',
                height: pxToRem(40),
                marginTop: pxToRem(20),
                flexDirection: 'column'
            }}
        >
            <LinearProgress
                variant="determinate"
                value={percent}
                sx={{
                    borderRadius: pxToRem(2),
                    '&.MuiLinearProgress-colorPrimary': {
                        background: '#FFF'
                    },
                    '& .MuiLinearProgress-barColorPrimary': {
                        background:
                            percent === 100
                                ? status === AssetStatus.SUCCESS
                                    ? '#00873F'
                                    : '#931621'
                                : '#171725'
                    }
                }}
            />
            <Typography sx={{ alignSelf: 'center', marginTop: pxToRem(10) }}>
                {percent === 100
                    ? status === AssetStatus.SUCCESS
                        ? 'Successfully Uploaded'
                        : 'Failed'
                    : 'Uploading...'}
            </Typography>
        </Box>
    );
};

const GetPreviewImage = (props: {
    file: File;
    isImage: boolean;
    previewElementStyle?: any;
    videoOverlayStyle?: any;
}) => {
    const {
        file,
        isImage,
        previewElementStyle = {},
        videoOverlayStyle = {}
    } = props;
    const objectUrl = useMemo(() => URL.createObjectURL(file), [file]);

    return isImage ? (
        <img alt={''} src={objectUrl} style={previewElementStyle} />
    ) : (
        <Box sx={{ ...previewElementStyle, position: 'relative' }}>
            <video src={`${objectUrl}#t=5`} style={previewElementStyle} />
            <VideoOverlay style={videoOverlayStyle} />
        </Box>
    );
};

interface PreviewAssetsProps {
    assets: AssetDetails[];
    assetUploadInProgress: boolean;
    isMultiFileUpload: boolean;
    isDarkMode: boolean;
    onDeleteAssetClick: (id: string) => void;
    onChangeAssetName: (id: string, name: string) => void;
    getAssetError: (id: string) => string;
}

const PreviewAssets = (props: PreviewAssetsProps): React.ReactElement => {
    const {
        assets,
        assetUploadInProgress,
        isMultiFileUpload,
        isDarkMode,
        onDeleteAssetClick,
        onChangeAssetName,
        getAssetError
    } = props;

    if (!isMultiFileUpload && assets) {
        const asset = assets[0];
        const file = asset.file;
        const canPreviewFile =
            file.type.startsWith('image') || file.type.startsWith('video');

        if (!canPreviewFile) return <></>;

        const isImage = file.type.startsWith('image');

        const previewElementStyle = {
            maxWidth: pxToRem(306),
            maxHeight: pxToRem(190)
        };
        const error = getAssetError(asset.id);

        return (
            <AssetDetailsContainer isDarkMode={isDarkMode}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        background: '#52526140',
                        height: pxToRem(190)
                    }}
                >
                    <GetPreviewImage
                        isImage={isImage}
                        previewElementStyle={previewElementStyle}
                        file={file}
                    />
                </Box>
                {assetUploadInProgress ? (
                    <AssetUploadProgressBar
                        percent={asset.uploadPercent}
                        status={asset.status}
                    />
                ) : (
                    <AssetNameContainer>
                        <CMPOutlinedTextField
                            mode="light"
                            maxLength={50}
                            label={`${cmpTexts.assets.name}`}
                            required={true}
                            fullWidth
                            onChange={(e) =>
                                onChangeAssetName(asset.id, e.target.value)
                            }
                            value={asset.name}
                            error={Boolean(error)}
                            size={'medium'}
                            rowHeight={46}
                            helperTextProps={{
                                content: (
                                    <CMPErrorHelperText
                                        text={
                                            asset.status === AssetStatus.FAILED
                                                ? cmpTexts.assets
                                                      .uploadRetryMessage
                                                : error
                                        }
                                    />
                                ),
                                sx: {
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    gap: 1
                                }
                            }}
                            disabled={
                                assetUploadInProgress ||
                                !ALLOWED_FILE_TYPES.includes(file.type)
                            }
                            sx={{
                                flex: 1,
                                background: 'transparent',
                                marginTop: pxToRem(10)
                            }}
                            InputProps={{
                                style: {
                                    color: '#000010'
                                }
                            }}
                            labelStyle={{
                                color: '#000010',
                                '&.MuiInputLabel-outlined.Mui-focused': {
                                    color: '#000010'
                                },
                                '&.MuiInputLabel-outlined.Mui-error': {
                                    color: '#000010'
                                }
                            }}
                        />

                        <Typography sx={{ paddingTop: pxToRem(34) }}>
                            {`.${file.name.split('.').pop()}`}
                        </Typography>
                        <DisableableBox disabled={assetUploadInProgress}>
                            <SingleDeleteIcon
                                onClick={() => {
                                    onDeleteAssetClick(asset.id);
                                }}
                            />
                        </DisableableBox>
                    </AssetNameContainer>
                )}
            </AssetDetailsContainer>
        );
    }

    return (
        <MultiAssetDetailsContainer isDarkMode={isDarkMode}>
            {assets.map((asset) => {
                const file = asset.file;
                const canPreviewFile =
                    file.type.startsWith('image') ||
                    file.type.startsWith('video');

                const isImage = file.type.startsWith('image');
                if (!canPreviewFile) return <></>;

                const previewElementStyle = {
                    maxWidth: pxToRem(120),
                    maxHeight: pxToRem(100)
                };

                const error = getAssetError(asset.id);
                const videoOverlayStyle = {
                    left: 27
                };

                return (
                    <Box
                        key={asset.id}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            gap: pxToRem(8)
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: pxToRem(120),
                                background: '#52526140',
                                height: pxToRem(100),
                                margin: pxToRem(8),
                                borderRadius: pxToRem(7),
                                flexShrink: 0,
                                overflow: 'hidden'
                            }}
                        >
                            <GetPreviewImage
                                file={file}
                                isImage={isImage}
                                previewElementStyle={previewElementStyle}
                                videoOverlayStyle={videoOverlayStyle}
                            />
                        </Box>
                        {assetUploadInProgress ? (
                            <AssetUploadProgressBar
                                percent={asset.uploadPercent}
                                status={asset.status}
                            />
                        ) : (
                            <AssetNameContainer>
                                <CMPOutlinedTextField
                                    mode="light"
                                    label={`${cmpTexts.assets.name}`}
                                    required={true}
                                    fullWidth
                                    onChange={(e) =>
                                        onChangeAssetName(
                                            asset.id,
                                            e.target.value
                                        )
                                    }
                                    value={asset.name}
                                    error={Boolean(error)}
                                    size={'medium'}
                                    helperTextProps={{
                                        content: (
                                            <CMPErrorHelperText
                                                text={
                                                    asset.status ===
                                                    AssetStatus.FAILED
                                                        ? cmpTexts.assets
                                                              .uploadRetryMessage
                                                        : error
                                                }
                                            />
                                        ),
                                        sx: {
                                            display: 'flex',
                                            position: 'absolute',
                                            justifyContent: 'flex-start',
                                            gap: 1
                                        }
                                    }}
                                    disabled={
                                        assetUploadInProgress ||
                                        !ALLOWED_FILE_TYPES.includes(asset.type)
                                    }
                                    sx={{
                                        flex: 1,
                                        background: 'transparent'
                                    }}
                                    InputProps={{
                                        style: {
                                            color: '#000010'
                                        }
                                    }}
                                    labelStyle={{
                                        color: '#000010',
                                        '&.MuiInputLabel-outlined.Mui-focused':
                                            {
                                                color: '#000010'
                                            },
                                        '&.MuiInputLabel-outlined.Mui-error': {
                                            color: '#000010'
                                        }
                                    }}
                                />

                                <Typography
                                    sx={{
                                        paddingTop: pxToRem(30),
                                        color: '#525261'
                                    }}
                                >
                                    {`.${file.name.split('.').pop()}`}
                                </Typography>
                            </AssetNameContainer>
                        )}
                        <DisableableBox disabled={assetUploadInProgress}>
                            <IconStylizer color="#171725">
                                <MultiDeleteIcon
                                    onClick={() => onDeleteAssetClick(asset.id)}
                                />
                            </IconStylizer>
                        </DisableableBox>
                    </Box>
                );
            })}
        </MultiAssetDetailsContainer>
    );
};

export default PreviewAssets;
