import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from '@redux-saga/core';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import rootSaga from './rootSaga';
import rootReducer from './rootReducer';
import { initialCoreRootState } from './coreRootState';

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
    rootReducer,
    //@ts-ignore
    initialCoreRootState,
    // const store = createStore(
    //     combineReducers(rootReducer),
    //     //@ts-ignore
    //     initialCoreRootState,
    composeWithDevTools(
        applyMiddleware(sagaMiddleware)
        // other store enhancers if any
    )
);
sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
