import { FileUploadOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import { first, includes, isArray, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { DropzoneArea } from 'react-mui-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import Button, { ButtonSize, ButtonVariation } from '../../components/button';
import CMPDialog, {
    CMPDialogActions,
    CMPDialogTitle
} from '../../components/cmpDialog';
import { SnackBarHOCProps, withSnackbar } from '../../components/snackbar';
import { useUrlParams } from '../../hooks/useParam';
import cmpTexts from '../../locale/en';
import {
    assetOperationLoaderReset,
    createAssets
} from '../../redux/assets/action';
import { AssetStatus } from '../../redux/assets/actionPayload';
import { Asset, OwnerType } from '../../redux/assets/model';
import {
    existingAssetsNames,
    selectAssetOperationLoadingState,
    selectAssets
} from '../../redux/assets/reducer';
import { LoadingStatus } from '../../redux/common/state';
import { MediaType } from '../../redux/element/model';
import { generateUUID } from '../../utils/stringUtils';
import { pxToRem } from '../../utils/stylesUtils';
import PreviewAssets from './previewAssets';

const FileDropBox = styled(Box, {
    shouldForwardProp: (prop: string) => prop !== 'isDarkMode'
})<{ isDarkMode: boolean }>(({ isDarkMode }) => ({
    marginBottom: pxToRem(24),
    height: '277px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    '& .MuiDropzoneArea-root': {
        width: pxToRem(336),
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none'
    },
    '& .MuiDropzoneArea-active': {
        background: isDarkMode
            ? 'rgba(196, 196, 202, 1)'
            : 'rgba(246, 245, 246)'
    },
    '& .MuiDropzoneArea-icon': {
        display: 'none'
    }
}));

export const MULTI_UPLOAD_FILE_LIMIT = 20;

export interface AssetDetails {
    id: string;
    file: File;
    status: AssetStatus;
    name: string;
    uploadPercent: number;
    type: string;
}

const DropZoneUI = (props: { isDarkMode: boolean }): React.ReactElement => {
    return (
        <Box
            sx={{
                justifySelf: 'center',
                color: props.isDarkMode ? '#fff' : '#000010'
            }}
        >
            <FileUploadOutlined
                sx={{ width: pxToRem(40), height: pxToRem(40) }}
            />
            <Typography fontWeight={800} fontSize={pxToRem(20)}>
                Drop your asset or{' '}
                <Typography
                    sx={{
                        display: 'inline',
                        color: '#F01446',
                        textDecoration: 'underline'
                    }}
                    fontWeight={800}
                    fontSize={pxToRem(20)}
                >
                    browse
                </Typography>
            </Typography>
            <Typography fontWeight={500} fontSize={pxToRem(16)} color="#C4C4CA">
                jpg, jpeg, png, mp4 or mov
            </Typography>
        </Box>
    );
};

interface FileDropZoneProps {
    onFileChange: (files: File[]) => void;
    onDropRejected: (message: string) => void;
    isDarkMode: boolean;
    filesLimit: number;
}

const FileDropZone = (props: FileDropZoneProps): React.ReactElement => {
    return (
        <Box
            sx={{
                border: '1px dashed gray',
                width: '100%',
                height: '100%',
                borderRadius: pxToRem(8)
            }}
        >
            <DropzoneArea
                filesLimit={props.filesLimit}
                showAlerts={false}
                showPreviewsInDropzone={false}
                showFileNames={false}
                //@ts-ignore
                Icon={null}
                //@ts-ignore
                dropzoneText={<DropZoneUI isDarkMode={props.isDarkMode} />}
                maxFileSize={200000000}
                acceptedFiles={['image/*', 'video/*']}
                onChange={props.onFileChange}
                onDropRejected={(files) => {
                    let message = '';

                    files.forEach((file) => {
                        if (file) {
                            if (file.size > 200000000) {
                                message += `"${file.name}" ${cmpTexts.assets.assetSizeExceeded} `;
                            } else if (
                                !includes(ALLOWED_FILE_TYPES, file.type)
                            ) {
                                message += `"${file.name}" is not a supported file type. `;
                            }
                        }
                    });

                    if (isEmpty(message)) {
                        message =
                            "The file that you have selected does not match CMP's Asset requirements.";
                    }

                    props.onDropRejected(message);
                }}
            />
        </Box>
    );
};

export const ALLOWED_FILE_TYPES = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'video/quicktime',
    'video/mp4'
];

interface UploadAssetProps {
    mediaType?: MediaType;
    mode?: 'dark' | 'light';
    setIsModalOpen: (show: boolean) => void;
    onAssetUploaded?: (asset: Asset) => void;
    filesLimit?: number;
}

const UploadAssetModal = (props: UploadAssetProps & SnackBarHOCProps) => {
    const {
        mode = 'light',
        setIsModalOpen,
        onAssetUploaded,
        snackbarShowMessage,
        filesLimit = 1
    } = props;

    const isDarkMode = mode === 'dark';
    const dispatch = useDispatch();
    const [isMultiFileUpload, setIsMultiFileUpload] = useState(false);
    const { property } = useUrlParams();
    const existingRemoteAssetNames = useSelector(existingAssetsNames);
    const uploadLoader = useSelector(selectAssetOperationLoadingState);

    const lastUploadedAsset = first(useSelector(selectAssets));
    const [cancelUploadController, setCancelUploadController] = useState<
        AbortController | undefined
    >(undefined);

    const assetUploadInProgress = uploadLoader === LoadingStatus.INITIATED;

    const formik = useFormik<AssetDetails[]>({
        initialValues: [],
        validationSchema: Yup.array().of(
            Yup.object()
                .shape({
                    name: Yup.string()
                        .trim()
                        .required('*Required')
                        .min(3, 'Should have atleast 3 characters')
                })
                .test(
                    'invalidTypes',
                    cmpTexts.assets.invalidAssetError,
                    (data, ctx) => {
                        if (!ALLOWED_FILE_TYPES.includes(data?.type))
                            return ctx.createError({
                                path: `${ctx.path}.name`,
                                message: cmpTexts.assets.invalidAssetError
                            });
                        return true;
                    }
                )
                .test(
                    'isNameUnique',
                    cmpTexts.assets.nameAlreadyExists,
                    (data, ctx) => {
                        const existingLocalNames = isArray(ctx.parent)
                            ? ctx.parent
                                  .filter((a) => a.id !== data?.id)
                                  .map((a) => a.name)
                            : [];

                        const existingAssetNames = [
                            ...existingRemoteAssetNames,
                            ...existingLocalNames
                        ];

                        if (existingAssetNames.includes(data.name))
                            return ctx.createError({
                                path: `${ctx.path}.name`,
                                message: cmpTexts.assets.nameAlreadyExists
                            });
                        return true;
                    }
                )
        ),
        onSubmit: () => {}
    });

    useEffect(() => {
        if (assetUploadInProgress) return;

        const nonUploadedAssets = formik.values.filter(
            (a) => a.status !== AssetStatus.SUCCESS
        );

        if (isEmpty(nonUploadedAssets) && formik.dirty) setIsModalOpen(false);
        else formik.setValues(nonUploadedAssets);
    }, [assetUploadInProgress, formik.dirty]);

    useEffect(() => {
        if (uploadLoader === LoadingStatus.DONE && lastUploadedAsset) {
            dispatch(assetOperationLoaderReset());
            onAssetUploaded?.(lastUploadedAsset);
        }
    }, [uploadLoader, lastUploadedAsset]);

    const handleUpload = () => {
        const assets = formik.values;

        if (isEmpty(assets)) return;

        const abortController = new AbortController();
        setCancelUploadController(abortController);

        const assetFilesObj = assets.map((asset, index) => {
            formik.setFieldValue(`[${index}].status`, AssetStatus.UPLOADING);
            formik.setFieldValue(`[${index}].uploadPercent`, 0);
            return {
                file: new File([asset.file], `${asset.name}`, {
                    type: asset.type
                }),
                id: asset.id
            };
        });

        dispatch(
            createAssets({
                files: assetFilesObj,
                owner_type: OwnerType.PROPERTY,
                owner_id: property,
                onUploadProgress: (percent, id, status) => {
                    const assets = formik.values;
                    const updatedAssets = assets.map((a) => {
                        if (a.id === id) {
                            a.uploadPercent = percent;
                            a.status = status as AssetStatus;
                        }
                        return a;
                    });
                    formik.setValues(updatedAssets);
                },
                cancelUploadController: abortController
            })
        );
    };

    const handleClose = (_?: any, reason?: any) => {
        if (reason === 'backdropClick' && assetUploadInProgress) return;

        setIsModalOpen(false);
        formik.handleReset('e');
    };

    const handleCancel = () => {
        if (!assetUploadInProgress) handleClose();

        const assets = formik.values;
        const nonUploadedAssets = assets
            .filter((a) => a.status !== AssetStatus.SUCCESS)
            .map((a) => ({
                ...a,
                uploadPercent: 0,
                status: AssetStatus.READY
            }));

        formik.setValues(nonUploadedAssets);

        cancelUploadController?.abort();
    };

    const onFileChange = (files: File[]) => {
        if (isEmpty(files)) return;
        setIsMultiFileUpload(files.length > 1);

        const selectedAssets: AssetDetails[] = files.map((f) => ({
            id: generateUUID(),
            file: f,
            status: AssetStatus.READY,
            name: f.name.split('.').slice(0, -1).join('.'),
            uploadPercent: 0,
            type: f.type
        }));

        formik.setValues(selectedAssets);
    };

    const onAssetNameChange = (assetId: string, name: string) => {
        const assetIndex = formik.values.findIndex(
            (a: AssetDetails) => a.id === assetId
        );
        if (assetIndex !== -1)
            formik.setFieldValue(`[${assetIndex}].name`, name);
    };

    const onDeleteAsset = (assetId: string) => {
        const updatedAssets = formik.values.filter((a) => a.id !== assetId);
        formik.setValues(updatedAssets);
    };

    const getAssetError = (assetId: string): string => {
        const assetIndex = formik.values.findIndex(
            (a: AssetDetails) => a.id === assetId
        );
        if (assetIndex !== -1 && formik.errors[assetIndex])
            return formik.errors[assetIndex]?.name ?? '';
        return '';
    };

    return (
        <CMPDialog
            onClose={handleClose}
            disableEscapeKeyDown={assetUploadInProgress}
            disableClose={assetUploadInProgress}
            maxWidth="sm"
            mode={mode}
            PaperProps={{
                style: {
                    background: isDarkMode ? '#171725' : '#fff',
                    boxSizing: 'content-box'
                }
            }}
        >
            <CMPDialogTitle
                sx={{
                    fontSize: pxToRem(28),
                    fontWeight: 800,
                    color: isDarkMode ? '#fff' : '#000010'
                }}
            >
                {cmpTexts.assets.modalHeading}
            </CMPDialogTitle>
            <FileDropBox
                isDarkMode={isDarkMode}
                sx={{
                    width:
                        isMultiFileUpload && formik.values.length > 0
                            ? pxToRem(502)
                            : pxToRem(336),
                    height:
                        formik.values.length > 3 ? pxToRem(456) : pxToRem(320)
                }}
            >
                {formik.values.length > 0 ? (
                    <PreviewAssets
                        assets={formik.values}
                        assetUploadInProgress={assetUploadInProgress}
                        isMultiFileUpload={isMultiFileUpload}
                        isDarkMode={false}
                        onDeleteAssetClick={onDeleteAsset}
                        onChangeAssetName={onAssetNameChange}
                        getAssetError={getAssetError}
                    />
                ) : (
                    <FileDropZone
                        filesLimit={filesLimit}
                        isDarkMode={isDarkMode}
                        onFileChange={onFileChange}
                        onDropRejected={(message) =>
                            snackbarShowMessage(message, 'error', 5000)
                        }
                    />
                )}
            </FileDropBox>
            <CMPDialogActions
                sx={{ gap: pxToRem(16), justifyContent: 'center' }}
            >
                <Button
                    buttonVariant={ButtonVariation.OUTLINED}
                    buttonSize={ButtonSize.MEDIUM}
                    onClick={handleCancel}
                    sx={
                        !isDarkMode
                            ? {
                                  color: '#000010',
                                  borderColor: '#000010'
                              }
                            : {}
                    }
                >
                    {cmpTexts.assets.cancel}
                </Button>
                <Button
                    buttonVariant={ButtonVariation.CONTAINED}
                    buttonSize={ButtonSize.MEDIUM}
                    disabled={
                        assetUploadInProgress ||
                        !formik.isValid ||
                        isEmpty(formik.values)
                    }
                    onClick={handleUpload}
                >
                    {cmpTexts.assets.upload}
                </Button>
            </CMPDialogActions>
        </CMPDialog>
    );
};

export default withSnackbar(UploadAssetModal);
